import { useRef } from "react";

export default function ChatWidget({ name }) {
    const chatEl = useRef(null);
    const chatRoot = "https://restartix-chat.web.app"; // http://localhost:5173
    const chatUrl = `${chatRoot}/rooms/seniori-restartix?name=${name}&v=0.0.1`;

    return (
        <iframe
            title="Event"
            ref={chatEl}
            src={chatUrl}
            style={{ height: "100%", width: "100%", border: 0 }}
        />
    );
}
