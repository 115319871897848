import { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import YoutubePlayer from "./common/YoutubePlayer";
import ChatWidget from "./common/ChatWidget";
import { getMediaCategoryItems } from "./core/services/firebase/firebase";
import { LoadingButton } from "@mui/lab";
import SocialTags from "./common/SocialTags";

const Logo = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#2f80ed",
    backgroundImage: "url(/logo.png)",
    backgroundSize: "80%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: 60,
    height: 60,
    borderBottomRightRadius: 24,
    zIndex: 1000,
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const PageContainer = styled(Box)(({ theme }) => ({
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
}));

const EventRegister = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
}));

const EventDesktop = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        marginRight: 400,
    },
}));

const EventVideo = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        maxWidth: 1060,
        margin: "0 auto",
        marginTop: theme.spacing(10),
    },
}));

const VideoItems = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(2,minmax(0,1fr))",
    margin: "0 auto",
    gap: theme.spacing(2),
    textAlign: "left",
    padding: theme.spacing(2),

    p: {
        fontSize: 16,
        fontWeight: 500,
    },

    [theme.breakpoints.up("lg")]: {
        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
        marginTop: theme.spacing(4),
        gap: theme.spacing(4),
        padding: 0,

        p: {
            fontSize: 20,
        },
    },
}));

const EventVideoPlayer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        backgroundColor: "#000",
        padding: theme.spacing(1),
        position: "relative",
        zIndex: 1,
    },
}));

const EventChat = styled(Box)(({ theme }) => ({
    flex: 1,
    minHeight: 480,
    height: "100%",
    [theme.breakpoints.up("md")]: {
        position: "fixed",
        right: 0,
        top: 0,
        height: "100%",
        width: 400,
        boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
        padding: 0,
    },
}));

function Event() {
    const loadedUser = JSON.parse(
        window.localStorage.getItem("rx_seniori_user")
    );

    const [list, setList] = useState([]);
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(loadedUser);
    const [form, setForm] = useState({
        name: "",
        email: "",
    });
    const [params] = useSearchParams();
    const loadedEventId = params.get("videoId") || "";

    useEffect(() => {
        getMediaCategoryItems("jHe1uQa8UuDoMZRURfyv").then((items) => {
            setInit(true);
            setList(items);
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [loadedEventId]);

    const currentVideo = useMemo(() => {
        if (!list) return null;

        if (loadedEventId) {
            return list.find((itm) => itm.id === loadedEventId);
        }

        if (list.length > 0) {
            return list[0];
        }

        return null;
    }, [list, loadedEventId]);

    const needsRegister = useMemo(() => {
        if (!user) return true;

        return false;
    }, [user]);

    const category1 = useMemo(() => {
        return list.filter((itm) => {
            return itm.createdAt.toDate() < new Date("2024-09-23");
        });
    }, [list]);

    const category2 = useMemo(() => {
        return list.filter(
            (itm) => itm.createdAt.toDate() >= new Date("2024-09-23")
        );
    }, [list]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setForm((old) => ({
            ...old,
            [name]: value,
        }));
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!form.email && !form.name) return;

        const data = {
            email: form.email,
            name: form.name,
            createdAt: new Date().toISOString(),
        };

        setLoading(true);

        await fetch(
            "https://hook.eu1.make.com/3da6eztyl43s23q3hcbbhbb5by9vww5u",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: form.email,
                    name: form.name,
                    listId: "279879",
                }),
            }
        );

        setUser(data);
        window.localStorage.removeItem("chat_id");
        window.localStorage.removeItem("chat_name");
        window.localStorage.setItem("rx_seniori_user", JSON.stringify(data));

        setLoading(false);
    };

    if (!init) {
        return (
            <PageContainer>
                <Logo />

                <EventRegister>
                    <Box>
                        <CircularProgress />
                    </Box>
                </EventRegister>
            </PageContainer>
        );
    }

    if (needsRegister) {
        return (
            <PageContainer>
                <SocialTags />

                <Logo />

                <EventRegister>
                    <Box>
                        <Box sx={{ maxWidth: 600, margin: "0 auto" }}>
                            <img
                                src="hero2-bg.jpg"
                                alt="Atelierele Excelenței"
                            />
                        </Box>
                        <Box sx={{ p: 4, backgroundColor: "#fafafa" }}>
                            <Typography variant="h6">
                                Atelierele Excelenței
                            </Typography>
                            <Typography sx={{ pb: 2 }}>
                                Interviuri realizate de Mihaela Tatu
                                <br />
                                în cadrul campaniei "Descoperă Valoarea
                                Experienței"
                            </Typography>
                            <Button
                                href="https://restartix.ro/ambasador-descopera-valoarea-experientei"
                                target="_blank"
                                variant="outlined"
                            >
                                Află mai multe
                            </Button>
                        </Box>

                        <Box
                            sx={{
                                p: 4,
                            }}
                        >
                            <Typography sx={{ mb: 2 }}>
                                Completează numele și emailul tău
                            </Typography>

                            <Box sx={{ maxWidth: 300, margin: "0 auto" }}>
                                <form onSubmit={handleRegister}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Numele tău"
                                                name="name"
                                                value={form.name}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Emailul tău"
                                                name="email"
                                                value={form.email}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                type="submit"
                                                disabled={loading}
                                                loading={loading}
                                            >
                                                Vreau să vizionez
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </EventRegister>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <Logo />

            <EventDesktop>
                <EventVideo>
                    <Box>
                        <EventVideoPlayer>
                            {currentVideo.youtubeId && (
                                <YoutubePlayer id={currentVideo.youtubeId} />
                            )}
                        </EventVideoPlayer>
                        <Box
                            sx={{
                                textAlign: "left",
                                p: 4,
                                backgroundColor: "#fafafa",
                            }}
                        >
                            <Typography variant="h6">
                                {currentVideo.title}
                            </Typography>
                            {currentVideo.description && (
                                <Typography
                                    sx={{ pt: 2, whiteSpace: "pre-line" }}
                                >
                                    {currentVideo.description}
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    {category2.length > 0 && (
                        <Box sx={{ my: 4, textAlign: "left" }}>
                            <Typography variant="h5">
                                Mai multe interviuri{" "}
                                <b>
                                    Personalități Descoperă Valoarea Experienței
                                </b>
                            </Typography>

                            <VideoItems>
                                {category2.map((item) => (
                                    <Box key={item.id}>
                                        <Link to={`/?videoId=${item.id}`}>
                                            <Box>
                                                <img
                                                    src={item.cover}
                                                    alt={item.title}
                                                />
                                            </Box>
                                            <Box sx={{ py: 1 }}>
                                                <Typography className="truncate">
                                                    {item.title}
                                                </Typography>
                                            </Box>
                                        </Link>
                                    </Box>
                                ))}
                            </VideoItems>
                        </Box>
                    )}

                    <Divider />

                    {category1.length > 0 && (
                        <Box sx={{ my: 4, textAlign: "left" }}>
                            <Typography variant="h5">
                                Mai multe interviuri{" "}
                                <b>Ambasadori Descoperă Valoarea Experienței</b>
                            </Typography>

                            <VideoItems>
                                {category1.map((item) => (
                                    <Box key={item.id}>
                                        <Link to={`/?videoId=${item.id}`}>
                                            <Box>
                                                <img
                                                    src={item.cover}
                                                    alt={item.title}
                                                />
                                            </Box>
                                            <Box sx={{ py: 1 }}>
                                                <Typography className="truncate">
                                                    {item.title}
                                                </Typography>
                                            </Box>
                                        </Link>
                                    </Box>
                                ))}
                            </VideoItems>
                        </Box>
                    )}
                </EventVideo>

                <Box sx={{ p: 4, backgroundColor: "#fafafa" }}>
                    <Typography variant="h6">Atelierele Excelenței</Typography>
                    <Typography sx={{ pb: 2 }}>
                        Interviuri realizate de Alexandru Ilie
                        <br />
                        în cadrul campaniei "Descoperă Valoarea Experienței"
                    </Typography>
                    <Button
                        href="https://restartix.ro/ambasador-descopera-valoarea-experientei"
                        target="_blank"
                        variant="outlined"
                    >
                        Află mai multe
                    </Button>
                </Box>
            </EventDesktop>

            <EventChat>
                <ChatWidget name={user.name} />
            </EventChat>

            <EventDesktop>
                <Box>
                    <Divider />
                    <Box sx={{ py: 5 }}>
                        <Typography>Descarcă aplicația RestartiX</Typography>
                        <Box sx={{ width: 200, m: "auto" }}>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.restartix.app"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/google-play-badge.png"
                                    alt="Descarcă aplicația RestartiX"
                                />
                            </a>
                        </Box>
                    </Box>
                </Box>
            </EventDesktop>
        </PageContainer>
    );
}

export default Event;
